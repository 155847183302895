@import 'src/styles/breakpoints';

.root {
  display: flex;
  flex-grow: 1;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex-shrink: 0;
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid lightgrey;

    .leadIdControls {
      display: flex;
      align-items: center;

      a {
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
      }

      .divider {
        margin: 0 0.4rem;
      }
    }

    .leadIdRow {
      display: flex;
      align-items: center;

      .label {
        font-size: 1rem;
        margin-right: 0;
      }

      .checkbox {
        margin: -0.3rem -0.4rem -0.3rem 0;
      }

      .onlyLink {
        visibility: hidden;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        cursor: pointer;
        margin-left: 0.5rem;
      }

      &:hover .onlyLink {
        visibility: visible;
      }
    }

    // used for both shopper & agent timelines
    .link {
      display: block;
      width: fit-content;

      .linkIcon {
        width: 1.125rem;
        height: 1.125rem;
        margin-left: 0.25rem;
        margin-bottom: -0.25rem;

        &:hover {
          filter: brightness(80%);
        }
      }
    }

    @include mobile {
      padding: 0 0.5rem 2rem 0.5rem;
      margin-right: 0;
      border-right: none;
    }
  }

  .right {
    flex-grow: 1;

    pre {
      font-size: 1rem;
      word-break: break-word;
      white-space: break-spaces;
      margin: 0;
    }
  }
}
