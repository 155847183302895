.root {
  position: sticky;
  top: 16.75rem;
  margin-top: 2rem;

  ul {
    margin-top: 0.5rem;
  }

  img {
    width: 84px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: -5px;
    margin-right: -8px;
  }
}
