@import 'src/styles/colors';

.smsMessage {
  white-space: pre-line; // show line breaks denoted by "\n" in the text
  font-size: 1rem;
  line-height: initial;
  border-radius: 0.5rem;
  background-color: $blue;
  color: white;
  padding: 0.5rem 0.7rem;
  margin: 0.25rem 0 0.2rem 0;
  width: fit-content;
  max-width: 32rem;
  // prevent long URLs from breaking out
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.received {
  background-color: #e9e9eb;
  color: inherit;
}

.system {
  background-color: white;
  color: inherit;
  border: solid $light-grey 1px;
}
