@import 'src/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5rem 2rem;

    @include mobile {
      padding: 0.5rem;
    }
  }
}

.appBar /* header */ {
  border-bottom: 1px solid lightgrey;
  box-shadow: none;

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    flex-shrink: 0;
    padding-left: 1rem;
    text-decoration: none;
    color: initial;

    img {
      margin-right: 0.75rem;

      @include mobile {
        margin: 0 0.35rem 0 0;
      }
    }

    span {
      @include mobile {
        display: none;
      }
    }
  }

  .toolbar {
    width: 100%;
    justify-content: space-between;

    @include mobile {
      padding: 0 0.5rem;
    }

    .username {
      @include mobile {
        display: none;
      }
    }
  }
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #dfdfdf;
  padding: 1.5rem;

  @include mobile {
    flex-direction: column;
  }

  .left {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    a {
      display: inline-block;

      @include desktop {
        margin-left: 1.5rem;
      }

      @include mobile {
        margin-top: 1rem;
      }

      &:first-child {
        margin: 0;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      margin-top: 1.5rem;
    }

    img {
      margin-left: 0.5rem;
      vertical-align: middle;

      @include mobile {
        margin-top: 0.5rem;
      }
    }
  }
}
