@import 'src/styles/breakpoints';

.root {
  display: flex;

  .left {
    max-width: 26rem;
    margin-right: 5rem;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  .right {
    width: 100%;
    margin-top: -0.75rem;
  }

  @include mobile {
    flex-direction: column;
  }
}
