@import 'src/styles/colors';

.root {
  margin-top: 0.5rem;
  padding: 0.5rem 0.75rem 0.25rem 0.75rem;
  border-radius: 0.5rem;

  &:first-child {
    margin-top: 0.25rem;
  }

  .body {
    margin-top: -0.1rem; // align text with the icon
  }
}

.error {
  background-color: #ffeaf0;
}

.warning {
  background-color: #fff8ea;
}

.info {
  background-color: #e5f6fd;
}
