.root {
  color: #051f2c;
  background-color: white;
  margin-left: 0.375rem;

  img {
    width: 18px;
    height: 18px;
    margin-left: -0.15rem;
    margin-right: 1px;
    margin-bottom: -0.25rem;
  }

  &:hover {
    background-color: #f1f1f1;
  }
}
