@import 'src/styles/breakpoints';

.controls {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  @include mobile {
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
  }
}
