@import 'src/styles/breakpoints';

.paper {
  position: sticky;
  top: 1.75rem;
  margin-top: 1.5rem;
  margin-right: -0.5rem;
  padding: 1rem 0 1rem 1.5rem;

  @include mobile {
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
  }
}

.filters {
  padding-right: 1.5rem;
  @include mobile {
    padding-right: 0;
  }
}

.helper {
  text-align: center;
  @include mobile {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
}
