@import 'src/styles/breakpoints';

.root {
  width: fit-content;
  min-width: 30rem;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  @include mobile {
    width: 100%;
    min-width: initial;
    padding: 0.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
