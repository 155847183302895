.activityFeed {
  width: 100%;
  border: 1px solid lightgrey;
  margin-top: 0.5rem;
  position: relative;

  .activity {
    display: block;
    color: inherit;
    text-decoration: inherit;

    &:hover {
      background-color: #f6fafd;
      border-radius: 0.5rem;
    }
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
  }
}
