@import 'src/styles/breakpoints';

.header {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.content {
  display: flex;

  .alert {
    width: 100%;
    height: fit-content;
    margin-top: 1.5rem;

    @include mobile {
      margin-top: 0;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}

:global(.infinite-scroll-component__outerdiv) {
  width: 100%;
  margin-top: 1.25rem;

  @include mobile {
    margin-top: 0;
  }
}

.activity {
  display: block;
  color: inherit;
  text-decoration: inherit;

  &:hover {
    background-color: #f6fafd;
    border-radius: 0.5rem;
  }
}
